.PageGraphiqueConstante {
  width: 100%;
}

.TitreEtBouton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Graphique {
  margin-top: 30px;
}

.BlocValeurs {
  margin-top: 50px;
  padding: 0px 20px;
}

.LigneValeurs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  border-top: 1px solid #ddd;
}

.DateValeurs {
  width: 200px;
  color: #666;
}

.Valeur {
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
}

.Unite {
  font-weight: 300;
}

.BoutonRetour {
  margin-top: 10px;
  display: none;
}

.Onglets {
  margin-top: 20px;
}

.BlocSuppression {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .BoutonRetour {
    display: block;
  }
}
