.NumberPicker:hover {
  cursor: pointer;
}

.Grid {
  display: flex;
  width: 180px;
  flex-wrap: wrap;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
}

.Unite {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: #f6f6f6;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #666;
}

.UniteSelectionne {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: #205a71;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: white;
}

.Unite:hover {
  border: 1px solid #205a71;
  color: #205a71;
  font-size: 900;
  background-color: #205a7110;
  cursor: pointer;
}

.Effacer {
  width: 110px;
  height: 50px;
  border-radius: 6px;
  background-color: #f6f6f6;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #666;
}

.Unite:hover,
.Effacer:hover {
  border: 1px solid #205a71;
  color: #205a71;
  font-size: 900;
  background-color: #205a7110;
  cursor: pointer;
}
