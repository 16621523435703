.SurfaceZoneSecondaire {
  background-color: #293c4710;
  padding: 20px;
  max-width: 400px;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  padding-bottom: 60px;
}

.IconeIllustration {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 120px;
  height: 120px;
  opacity: 0.2;
  z-index: 1;
}

.Children {
  z-index: 2;
  position: relative;
}

/********* PETIT ECRAN  *************/
@media screen and (min-width: 820px) and (max-width: 1200px) {
  .SurfaceZoneSecondaire {
    padding: 20px;
    padding-bottom: 60px;
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .SurfaceZoneSecondaire {
    padding: 20px;
    border-radius: 0px;
    padding-bottom: 60px;
  }
}
