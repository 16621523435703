.PageInscription {
  display: flex;
  flex-direction: row;
}

.Content {
  background-color: white;
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
