.EnteteApp {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 15px;
  box-sizing: border-box;
}

.BoutonsEntete {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  gap: 10px;
}

.Logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BoutonMenuMobile {
  display: none;
  width: 40px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .EnteteApp {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 15px;
  }

  .BoutonMenuMobile {
    display: inline;
  }
}
