.PageCotationsAFacturer {
  width: 100%;
}
.BoutonRetour {
  margin-top: 20px;
  display: none;
}

.Content {
  margin-top: 20px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BoutonRetour {
    display: flex;
  }
}
