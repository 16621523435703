.EditionEvenement {
  width: 320px;
  padding: 15px;
  border: 0px solid red;
  max-height: 470px;
  overflow: scroll;
}

.TitreEtBouton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.View {
  min-height: 380px;
}

.Bloc {
  min-height: 50px;
  display: flex;
  align-items: center;
}

.SurfaceBloc {
  margin: 20px;
}

.Label {
  width: 100%;
  cursor: pointer;
}

.LabelBleu {
  width: 100%;
  cursor: pointer;
  color: #208ab0;
}

.Choix {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.NomEvement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  min-height: 50px;
}
