.EditionConseilPrise {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f6f6f6;
  padding: 10px;
}

.Phrase {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 15px;
}

.Select {
  width: 130px;
  margin: 0px;
}

.Texte {
  height: 40px;
  padding-top: 10px;
  box-sizing: border-box;
  color: #666;
}
