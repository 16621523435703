.Boutons {
  border: 0px solid red;
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .Boutons {
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 40px;
  }
}
