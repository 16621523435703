.content {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: space-around;
}

.InputCode {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-top: 10px;
}

.partieGauche {
  width: 45%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}
.partieDroite {
  width: 40%;
}

.formulaire {
  margin-bottom: 40px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .content {
    margin-top: 20px;
  }

  .partieGauche {
    width: 100%;
    min-width: 0px;
    display: flex;
    flex-direction: column;
  }

  .partieDroite {
    display: none;
  }
}
