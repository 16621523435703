/*************** COMPOSANT JOUR ******************************/

.Jour {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  padding-bottom: 2px;
}
.Jour:hover {
  background-color: #205a7110;
}

.Aujourdhui {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border-radius: 25px;
  color: #205a71;
  font-weight: 900;
  background-color: #205a7120;
}

.JourSelectionne {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border-radius: 25px;
  font-weight: 900;
  color: white;
  background-color: #205a71;
}

.JourAutreMois {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #97989d;
}

/*************** COMPOSANT DATEPICKER ******************************/

.CalendrierEtSemaines {
  display: flex;
  flex-direction: row;
}

.ColonneNumerosSemaine {
  min-width: 30px;
  margin-top: 40px;
  display: grid;
}

.NumeroSemaine {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #205a71;
  font-style: italic;
}

.Calendrier {
  width: 100%;
}

.listeDates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row: 1fr;
}

.ListeJours {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #ddd;
  min-height: 40px;
}

.NomJour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  color: #666666;
  height: 40px;
}

.ChoixMois {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  color: #666;
  font-family: Raleway;
  height: 40px;
}

.CaseJour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
