.EditionConseil {
  padding-bottom: 200px;
}
.Boutons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
}

.Lignes {
  margin-top: 20px;
}

.NouveauProduit {
  background-color: #28781920;
  padding: 20px;
  border-top: 2px solid #287819;
  border-bottom: 2px solid #287819;

  display: flex;
  flex-direction: row;
  margin: 20px 0px 40px 0px;
}

.Autocomplete {
  flex: 1;
}
