.ColonneJour {
  position: relative;
}

.ColonneJourNonTravaille {
  position: relative;
  background-color: red;
}

.NbRdv {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #666;
  font-style: italic;
  background-color: white;
  padding-top: 5px;
}
