.PageJournalDesFactures {
  display: flex;
  flex-direction: column;
}

.TitreEtBoutons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.BoutonRetour {
  margin-top: 20px;
  display: none;
}

.Content {
  margin-top: 20px;
}

.EnteteColonnes {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #666;
  font-size: 12px;
  text-transform: uppercase;
  color: #97989d;
}

.ColonneFacture {
  flex: 1;
}
.ColonneHT,
.ColonneTva,
.ColonneTTC {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ColonnePartPatient {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Total {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-top: 1px solid #666;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageJournalDesFactures {
    width: calc(100vw - 60px);
    box-sizing: border-box;
  }

  .BoutonRetour {
    margin-top: 20px;
    display: flex;
  }

  .ColonneHT,
  .ColonneTva {
    display: none;
  }
}
