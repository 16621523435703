.PageFacturesARegler {
  width: 100%;
}

.ListeFactures {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}

.TitreGroupe {
  margin-bottom: 40px;
}

.ColonneFacture {
  width: 50%;
}

.ColonnePartPatient {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;
}
/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .ColonneFacture {
    width: 80%;
  }
  .ColonnePartPatient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    width: 150px;
  }
}
