.InfoAdpx {
  height: 40px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px 0px 0px;
  font-weight: 700;
  color: white;
  gap: 10px;
  cursor: pointer;
}

.Popover {
  padding: 20px;
  padding-bottom: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  font-size: 100%;
  max-width: 80vw;
  position: relative;
}

.BoutonClose {
  position: absolute;
  right: 10px;
  top: 10px;
}

/********* MOBILE *************/
@media screen and (max-width: 1000px) {
  .InfoAdpx {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    font-weight: 700;
    color: white;
    gap: 10px;
  }

  .Detail {
    display: none;
  }
}
