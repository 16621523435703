.ZoneRechercheFocus {
  height: 60px;
  background: #ffffff;
  border: 1px solid #208ab0;
  box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  width: 650px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.ZoneRecherche {
  height: 60px;
  background: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  width: 650px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.PartieGauche {
  width: 410px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
}

.PartieDroite {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

/********* MOBILE *************/

@media screen and (max-width: 840px) {
  .ZoneRechercheFocus {
    height: 50px;
    background: #ffffff;
    border: 1px solid #208ab0;
    box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
  }

  .ZoneRecherche {
    height: 50px;
    background: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
  }

  .PartieGauche {
    width: 100%;
  }
  .PartieDroite {
    display: none;
  }
}
