.TitreEtBouton {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.ZoneRecherche {
  margin: 0px 0px;
}

.InputRecherche {
  width: 300px;
  margin-right: 30px;
}

.Content {
  padding-bottom: 200px;
  margin-top: 20px;
}

.ColonneNom {
  flex: 3;
}

.ColonnePrix {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .TitreEtBouton {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .BoutonActionSecondaire {
    width: 100%;
    margin-top: 20px;
  }

  .InputRecherche {
    width: 100%;
    margin-right: 30px;
  }
}
