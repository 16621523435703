.Form {
  background-color: #f6f6f6;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 2px solid #f6f6f6;
  border-radius: 4px;
}

.FormFocus {
  background-color: #f6f6f6;
  border: 2px solid #208ab0;
  border-radius: 4px;

  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.IconeHistorique {
  width: 30px;
  color: #97989d60;
}
