.SurfacePanneau {
  border: 0px solid red;
  padding: 20px;
  min-width: 400px;
}

.TitreEtBouton {
  border: 0px solid green;
  justify-content: flex-start;
  gap: 10px;
}
