.PageAllAgendas {
  max-width: calc(100%);
}

.Agenda {
  display: flex;
  flex-direction: row;
  /*width: fit-content;*/
  max-width: 100%;
  width: 100%;
}
.EnteteEtListesJours {
  width: calc(100% - 70px);
}

.EnteteAgenda {
  margin-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 5;
}

.ColonneHeures {
  position: sticky;
  left: 0px;
  z-index: 5;
  padding-left: 10px;
  background-color: white;
}

.ListesJours {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.Jour {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  border-right: 5px solid white;
  border-left: 5px solid white;
}

.JourParAgenda {
  flex: 1;
  border-right: 1px #dddddd solid;
  border-left: 1px #dddddd solid;
}

.NomAgenda {
  position: sticky;
  top: 80px;
  z-index: 5;
}
