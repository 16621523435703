.ListeReglements {
  padding: 0px;
}

.LigneReglement {
  background-color: #f9f9f9;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.BoutonAnnuler {
  margin-left: 30px;
  width: 120px;
}
