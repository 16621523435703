.HelloApi {
  min-width: 150px;
  width: fit-content;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Icone {
  font-size: 50px;
}

.Textes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .HelloApi {
    min-width: 100%;
    width: 100%;
  }

  .Content {
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
  }

  .Icone {
    font-size: 30px;
  }
  .Textes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
