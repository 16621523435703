.EditionReglement {
  margin-top: 20px;
}

.BlocReglement {
  background-color: #f9f9f9;
  padding: 15px;
}

.MontantEtBoutons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.Montant {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  min-width: 140px;
  margin-bottom: 20px;
}

.Boutons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex: 5;
  margin-left: 5px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.LigneReglement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  min-height: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BlocReglement {
    background-color: #f9f9f9;
    padding: 0px;
  }
  .MontantEtBoutons {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
  }

  .Montant {
    width: 100%;
  }
}
