.EditionTelephone {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.EditionTelephone:hover {
  cursor: pointer;
}

.Telephone {
  width: 100%;
}

.PasDeTelephone {
  width: 100%;

  color: #97989d;
}
.IconeTelephone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  margin-left: 10px;
  margin-right: 3px;
}
