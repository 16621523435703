.HorairesSemaine {
  margin-top: 10px;
}

.LigneJour {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NomJour {
  width: 40px;
  font-size: 18px;
  font-weight: 500;
}

.Divider {
  margin-top: 10px;
}
