.PageCarteBancaire {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
}

.Loader {
  border: 0px solid red;
  margin: 100px;
}

.PartieGauche {
  border: 0px solid green;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  max-width: 500px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PartieGauche {
    border: 0px solid green;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: unset;
  }

  .PageCarteBancaire {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
