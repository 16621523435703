.PageApdxOrganisations {
  padding: 30px;
}

.BoutonRond {
  display: none;
  position: absolute;
  right: 10px;
  background-color: #ffffff90;
}

.Ligne {
  background-color: white;
  border: 2px solid #dddddd50;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
  position: relative;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageApdxOrganisations {
    padding: 15px;
  }

  .BoutonRond {
    display: inline;
  }

  .BoutonActionSecondaire {
    display: none;
  }
}
