.Color {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid #208ab000;
  width: 50px;
  min-width: 30px;
  height: 50px;
  cursor: pointer;
}

.Color:hover {
  background-color: #dddddd80;
}

.SvgIcon {
  width: 32px;
  height: 32px;
}

.ColorSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid #208ab0;
  width: 50px;
  min-width: 30px;
  height: 50px;
  cursor: pointer;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .Color {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid #208ab000;
    width: 40px;
    height: 40px;
    min-width: 1fr;
  }

  .Color:hover {
    background-color: #dddddd80;
  }

  .ColorSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid #208ab0;
    width: 40px;
    height: 40px;
  }

  .SvgIcon {
    width: 24px;
    height: 24px;
  }
}
