.Periode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Titre {
  display: none;
}

.BoutonRetour {
  display: none;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .Titre {
    display: flex;
  }

  .BoutonRetour {
    display: flex;
  }
}
