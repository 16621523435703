.AfficherRdv {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  opacity: 0.9;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #ffffff;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
  font-size: 16px;
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
}

.AfficherRdvAnnule {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  opacity: 0.9;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #ffffff;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
  font-size: 16px;
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(
    45deg,
    #ddd 5%,
    #fff 5%,
    #fff 45%,
    #ddd 45%,
    #ddd 55%,
    #fff 55%,
    #fff 95%,
    #ddd 95%
  );
  background-size: 10px 10px;
  background-position: 0 0;
  border: 1px solid #ddd;
  color: #97989d;
}

.AfficherRdv:hover {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  opacity: 0.9;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #ffffff;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
  font-size: 16px;
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.NomPrenom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
}

.Nom {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 3px;
}

.Prenom {
  font-family: Lato;
  font-weight: 400;
  text-transform: capitalize;
}

.Patient {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
