.BlocPatient {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.PatientEtBoutons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.Icone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  margin-left: 20px;
  margin-right: 15px;
}

.Patient {
  width: 100%;
}

.Autocomplete {
  width: 100%;
  padding: 0px 10px;
}
