.DateConsultation {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
  height: 30px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
}

.DateConsultation:hover {
  cursor: pointer;
  background: #f6f6f6;
}

.DatePicker {
  border: 0px solid red;
  min-width: 300px;
}
