.ObjetDemande {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: start;
}

.ObjetDemandeL {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 50px;
}

.Icone {
  border-left: 3px solid #ddd;
  height: 40px;
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Demande {
  flex: 1;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .EtatDemande {
    display: none;
  }
}
