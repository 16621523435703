.SpecialitePicker {
  border: 0px solid green;
}

.ListeSpecialites {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid red;
  justify-content: center;
  gap: 10px;
}

.Specialite {
  font-size: 15px;
  border: 1px solid #97989d;
  padding: 2px 10px;
  border-radius: 15px;
  color: #97989d;
}

.Specialite:hover {
  font-size: 15px;
  border: 1px solid #208ab0;
  padding: 2px 10px;
  border-radius: 15px;
  color: #208ab0;
  cursor: pointer;
}

.SpecialiteSelected {
  font-size: 15px;
  border: 1px solid #208ab0;
  padding: 2px 10px;
  border-radius: 15px;
  color: white;
  background-color: #208ab0;
  cursor: pointer;
}
