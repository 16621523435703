.IconeMesurePicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.BoutonIcone {
  border: 2px solid #dddddd80;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #97989d;
  border-radius: 6px;
}

.BoutonIcone:hover {
  background-color: #208ab010;
  color: #208ab0;

  cursor: pointer;
}

.BoutonIconeSelected {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 2px solid #208ab0;
  color: #208ab0;
  cursor: pointer;
}
