.ObjetContact {
  border: 0px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Contact {
  border: 0px solid blue;
  flex: 1;
}
.Boutons {
  border: 0px solid green;
  display: flex;
  align-items: center;
  gap: 10px;
}

.InformationsSecondairesL {
  border: 0px solid red;
  font-size: 14px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.LigneSecondairesL {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .ObjetContact {
    flex-direction: column;
    gap: 0px;
  }

  .Boutons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
