.EnteteColonne {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EnteteColonne:hover {
  color: #205a71;
  cursor: pointer;
}
