.PageAgendas {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-height: 100%;
  border: 0px solid pink;
}

.Titre {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f2f1f5;
  position: sticky;
  top: 0px;
  z-index: 40;
  margin-bottom: 20px;
}

.Content {
  display: flex;
}

.PartieGauche {
  max-width: 300px;
  overflow: hidden;
  padding: 20px;
  max-height: 100%;
  overflow: scroll;
}

.PartieGaucheMinimise {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  width: 60px;
  padding: 10px 0px;
  gap: 20px;
  cursor: pointer;
  border-radius: 4px 0px 0px 4px;
  max-height: 100%;
  overflow: scroll;
}

.PartieDroite {
  width: 100%;
  max-height: 100%;
  overflow: scroll;

  /* max-width: calc(100%);*
 /* overflow: auto;*/
  border: 0px solid blue;
}

.DatePicker {
  margin-top: 20px;
  margin-bottom: 40px;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .PageAgendas {
    padding: 0px;
    flex-direction: column;
    overflow: scroll;
  }

  .Content {
    min-height: calc(100vh - 70px - 60px);
    display: flex;
  }
  .PartieGauche {
    display: none;
  }
  .PartieDroite {
    width: 100%;
    /* max-width: calc(100%);*
   /* overflow: auto;*/
    min-height: calc(100vh - 70px - 60px);
  }
}
