.AffichageAncienneMesure {
  height: 60px;
  border: 1px solid #dddddd;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 20px;
  color: #205a71;
  cursor: pointer;
}

.AffichageAncienneMesureSelected {
  height: 60px;
  border: 1px solid #dddddd;
  background-color: #205a71;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 20px;
  color: white;
  cursor: pointer;
}

.AffichageAncienneMesure:hover {
  background-color: #f6f6f6;
}

.Icone {
  width: 50px;
  height: 50px;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AffichageAncienneMesureM {
  display: flex;
  flex-direction: row;
  height: 50px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.Valeur {
  color: black;
  font-weight: 700;
  font-size: 16px;
}

.Unite {
  color: black;
  margin-left: 5px;
  font-size: 12px;
}

.Date {
  font-family: Lato;
  font-size: 12px;
  color: #97989d;
}

.Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding-right: 20px;
}

.Link {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
}

.Label {
  font-size: 11px;
  color: #666666;
  background-color: white;
  position: absolute;
  top: -8px;
  left: 15px;
  padding: 0px 7px;
}
