.AffichageCabinetUtilisateur {
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff10;
  padding-right: 15px;
}

.AffichageCabinetUtilisateur:hover {
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff20;
  padding-right: 15px;
  cursor: pointer;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .AffichageCabinetUtilisateur {
    display: none;
  }
}
