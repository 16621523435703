.PageRendezVous {
  max-width: 1000px;
  padding: 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  background-color: white;
}

.Filtres {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;
}

.ListeRdv {
  margin-top: 0px;
}

.Ligne {
  background-color: white;
  border-bottom: 1px solid #ddd;
  height: 60px;
  display: flex;
}
