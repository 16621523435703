.PageListeFactures {
  border-bottom: 1px solid #ddd;
}
.Ligne {
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.Date {
  flex: 1;
}

.DetailSms {
  margin-left: 40px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .DetailSms {
    display: none;
  }
}
