.ImageSmall {
  width: 100%;
  height: auto;
}

.ImageLarge {
  width: 90vw;
}

.Url {
  background-color: black;
  color: white;
  padding: 10px;
}
