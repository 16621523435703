.SyntheseConsultation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
  border: 0px solid green;
  padding: 10px;
  min-height: 40px;
}
