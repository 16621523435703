.HuitRegles {
  background-color: #f6f6f6;
  padding: 0px;
  max-width: 300px;
  padding: 10px;
}

.OrganeEtBouton {
  display: flex;
  flex-direction: row;
}

.Regles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.EditionHuitRegles {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 5px;
}
