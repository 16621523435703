.AffichageAcceptationCgv {
  border: 0px solid red;
  width: 50vw;
  margin-top: -20px;
}

.Boutons {
  display: flex;
  flex-direction: column;
  border: 0px solid blue;
  gap: 20px;
  margin-bottom: 40px;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .AffichageAcceptationCgv {
    width: unset;
  }
}
