.BlocTypeRdv {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.LibelleTypeRdv {
  width: 100%;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  max-height: 50px;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.Choix {
  width: 70px;
  justify-content: flex-end;
}
