.BlocRdvSupprime {
  height: 50px;
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BlocRdvSupprime:hover {
  opacity: 0.9;
}
