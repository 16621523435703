.Titre {
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Illustration {
  margin: 30px;
  border: 0px solid green;
}
.LogoCnil {
  max-width: 200px;
}
