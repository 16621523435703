.SeparateurAfficherPlus {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.BoutonAfficher {
  min-width: content;
  padding-left: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 20px;
  color: #666;
  font-size: 12px;
}

.BoutonAfficher:hover {
  border: 1px solid #208ab0;

  color: #208ab0;
  background-color: #208ab010;
  cursor: pointer;
}
