.TypeConsultation {
  display: flex;
  cursor: pointer;
  padding: 0px 5px;
  align-items: center;
}

.TypeConsultation:hover {
  display: flex;
  cursor: pointer;
  background: #dddddd80;
  border-radius: 4px;
  padding: 0px 5px;
  color: #205a71;
}
