.PanneauContacts {
  min-width: 500px;
  max-width: 500px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f2f1f5;
  min-height: 100vh;
  border: 0px solid red;
  max-height: 100vh;
  overflow: scroll;
}

.TitreEtBouton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NePasSauvegarder {
  padding: 0px 20px 10px 20px;
  background-color: #dddddd50;
  border: 1px solid #dddddd;
  border-radius: 4px;
  cursor: pointer;
}

.NePasSauvegarder:hover {
  padding: 0px 20px 10px 20px;
  background-color: white;
  border: 1px solid #208ab0;
  border-radius: 4px;
  cursor: pointer;
}

.Sauvegarder {
  padding: 0px 20px 10px 20px;
  background-color: #0fa60010;
  border: 1px solid #0fa600;
  border-radius: 4px;
  cursor: pointer;
}
