.Panneau {
  width: 500px;
  padding: 30px;
  box-sizing: border-box;
}

.BoutonTitre {
  display: flex;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Panneau {
    width: 340px;
    padding: 20px;
    box-sizing: border-box;
  }
}
