.Sms {
  min-height: 25px;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 15px 0px;
  padding: 4px 20px 4px;
  color: white;
  font-size: 16px;
  width: fit-content;
  max-width: 500px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.InformationsSecondaire {
  display: flex;
  flex-direction: row;
  border: 0px solid red;
  align-items: center;
  font-size: 14px;
  margin-top: 4px;
  justify-content: space-between;
}

.DateEnvoi {
  font-size: 14px;
  color: #666666;
}

.Suivi {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: green;
  gap: 5px;
}

.SuiviError {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: red;
  gap: 5px;
}
