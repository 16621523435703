.BlocRappel {
  height: 50px;
  border: 0px solid purple;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.BlocTexte {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
