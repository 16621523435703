.Panneau {
  width: 450px;
  padding: 20px;
}

.Bloc {
  margin-bottom: 20px;
}

.BoutonTitre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.BlocRdvAnnules {
  display: flex;
  flex-direction: row;
}

.ItemBloc {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  padding-right: 10px;
}

.ItemBloc:hover {
  cursor: pointer;
  background-color: #dddddd50;
}

.Aucun {
  font-style: italic;
  font-size: 14px;
  color: #97989d;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-left: 15px;
}

.NouveauRdv {
  font-size: 40px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #97989d;
  font-family: Raleway;
  font-weight: 300;
  width: 100%;
}
