.PagePatientsRecents {
  width: 100%;
  border: 0px solid red;
  box-sizing: border-box;
}

.TitreEtBouton {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 0px solid red;
}

.Boutons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.TableauResultat {
  margin-top: 30px;
  border: 0px solid red;
}

.Content {
  min-height: 80vh;
  border: 0px solid green;
  box-sizing: border-box;
}

.Resultats {
  border: 0px solid orange;
}

.TitreGroupe {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  color: #97989d;
  font-weight: 700;
  font-size: 16px;
  height: 40px;
  margin-left: 10px;
  font-family: Raleway;
}
.ColonnePatient {
  flex: 1;
}

.ColonneTelephone,
.ColonneAdresse {
  flex: 1;
  border: 0px solid blue;
}

.ZoneRecherche {
  border: 0px solid red;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Heure {
  margin-right: 40px;
  color: #666;
  font-family: Lato;
  font-weight: 300;
  font-size: 14px;
}
/********* MOBILE *************/

@media screen and (max-width: 840px) {
  .OngletsPatients {
    display: none;
  }

  .PagePatientsRecents {
    width: 100%;
    min-height: calc(100vh - 70px);
  }

  .Boutons {
    display: none;
  }

  .Content {
    padding: 0px;
    height: auto;
  }
  .ColonneTelephone,
  .ColonneAdresse {
    display: none;
  }

  .ColonnePatient {
    width: 80%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
  }

  .Heure {
    margin-right: 20px;
    margin-left: 15px;
  }
}
