.PageNouvelleDemande {
  max-width: 800px;
  padding: 20px 40px;
}

.ListeType {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.UrgenceDemande {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
}

.UrgenceDemande:hover {
  border: 1px solid #208ab0;
}

.Formulaire {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
}
