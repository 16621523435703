.PageConstantes {
  padding: 20px;
  overflow: scroll;
  height: calc(100vh - 195px);
}

.BlocTitre {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ListeMesures {
  width: 320px;
  border-right: 1px solid #dddddd;
  padding-right: 20px;
}

.Graphique {
  background-color: #f6f6f630;
  padding-left: 20px;
  width: calc(100% - 320px - 40px);
}

/********* TAILLE PETIT ECRAN *************/

@media screen and (min-width: 820px) and (max-width: 1200px) {
  .ListeMesures {
    width: 200px;
    border-right: 0px solid #dddddd;
    padding-right: 0px;
  }
  .Graphique {
    background-color: #f6f6f650;
    padding-left: 20px;
    width: calc(100% - 200px - 40px);
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .BlocTitre {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .PageConstantes {
    margin: 0px;
    padding: 20px;
    background-color: white;
    min-height: 100vh;
  }
  .ListeMesures {
    width: 100%;
    border-right: 0px solid #dddddd;
    padding-right: 0px;
  }
}
