.PageLogin {
  display: flex;
  flex-direction: row;
  background-color: white;
  max-height: 100vh;
  height: fit-content;
  box-sizing: border-box;
  border: 0px solid green;
}

.Titre {
  color: white;
}

.Formulaire {
  width: 100%;
  padding: 0px 30px;
  box-sizing: border-box;
  border: 2px solid red;
}

.LoginPasswordMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  z-index: 2;
}

.PartieDroite {
  width: 80%;
  background-color: white;
  height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Actualites {
  max-width: 80%;
}
.ConnexionMobile {
  display: none;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .PartieDroite {
    display: none;
  }

  .PageLogin {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .Formulaire {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
  }
}
