.IconePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.Icone {
  height: 45px;
  width: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 10px;
  margin-right: 20px;
  color: #97989d;
}

.Icone:hover {
  cursor: pointer;
  border: 1px solid #186d1c;
  color: #186d1c;
}

.IconeDisabled {
  height: 45px;
  width: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-right: 20px;
  background-color: #ededed;
  color: #97989d;
}

.IconeSelectionne {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #186d1c;
  padding: 10px;
  margin-right: 20px;
  color: #186d1c;
}

.IconeSelectionneDisabled {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid black;
  padding: 10px;
  margin-right: 20px;
  color: black;
  background-color: #ededed;
}
