.PageSynthese {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  padding-bottom: 200px;

  border: 0px green solid;
  max-height: 100%;
  overflow: scroll;
}

.BarreOutils {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 0px 10px;
  box-sizing: border-box;
  border: 0px solid red;
}

.TitreNouveauPatient {
  font-size: 80px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #ddd;
  font-family: Raleway;
  font-weight: 300;
  width: 100%;
}
/********* MOBILE *************/

@media screen and (max-width: 840px) {
  .PageSynthese {
    border-radius: 6px 0px;
    padding: 20px;
    padding-bottom: 200px;

    /*background-color: #f6f6f6;*/
  }
}
