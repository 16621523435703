.LigneConseil {
  border-top: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
}

.Ligne {
  width: 100%;
}

.Autocomplete {
  width: 100%;
}

.AffichageProduitPosologie {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 100%;
}

.Produit {
  display: flex;
  flex-direction: row;
  flex: 2;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  min-width: 320px;
}

.BoutonActions {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.Posologie {
  flex: 1;
  padding-left: 20px;
}

.AffichageZoneComplementaire {
  box-sizing: border-box;
  padding-left: 30px;
}
