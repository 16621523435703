.ColonneLaboratoire {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex: 2;
}

.ColonneCode {
  border: 0px solid blue;
  flex: 1;
  cursor: pointer;
}
