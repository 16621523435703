.ZoneRecherche {
  min-height: 40px;
  margin: 20px 0px;
}

.Liste {
  padding-bottom: 60px;
}

.Ligne {
  background-color: white;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0px;
  padding: 5px 10px;
}

.CheckBox {
  margin-right: 10px;
}

.ColonneNom {
  flex: 3;
  cursor: pointer;
}

.ColonnePrix {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer;
  min-width: 80px;
}

.BarreSelectionMultiple {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 60px);
  box-sizing: border-box;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0px 15px;
}
/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BoutonAjouterPrestation {
    display: none;
  }

  .BoutonEffacerSelection {
    display: none;
  }

  .BarreSelectionMultiple {
    width: calc(100% - 30px);
  }

  .CheckBox {
    margin-right: 10px;
    margin-right: 0px;
  }
}
