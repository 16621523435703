/*********  AGENDA *************/

.Agenda {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.HeuresEtJours {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: top;
}

.ListeColonnes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 200px;
  width: 100%;
}

.ColonneHeures {
  margin-top: -8px;
  width: 50px;
}

.Colonne {
  flex: 1;
  border-right: 1px solid #ddd;
}

.ColonneJourSelectionne {
  flex: 1;
  border-right: 1px solid #ddd;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .Colonne {
    display: none;
  }
  .HeuresEtJours {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: top;
    padding: 0px 10px;
    box-sizing: border-box;
  }
}
