.Bloc {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0px solid purple;
  height: 50px;
  cursor: pointer;
  box-sizing: border-box;
}

.EditionRappel {
  border: 0px solid orange;
}

.SmsEnvoyes {
  margin-top: 40px;
}

.ListeSmsEnvoyes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0px;
}
