.ObjetTypeRdv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-right: 10px;
}

.Icone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  margin-left: 15px;
  margin-right: 15px;
}

.Libelle {
  width: 100%;
}

.Duree {
  color: #666666;
  font-size: 14px;
}
