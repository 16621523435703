.MenuMobile {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 15px;
  left: 0px;
  width: 100%;
}

.BoutonFermer {
  padding-left: 20px;
}

.Contenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.ItemsMenu {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.BoutonMenu {
  color: black;
  background-color: white;
  /*background: #ffffff;*/

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  padding: 5px;
  height: 80px;
  width: 80px;
}

.BoutonMenu:active {
  background-color: #ffffff80;
  box-shadow: none;
}

.BoutonMenuIcone {
  margin-top: 10px;
}

.BoutonMenuLabel {
  color: #00000080;
}
