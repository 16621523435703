.BoutonOuiNon {
  display: flex;
  justify-content: space-between;
}

.BoutonNonSelectionne {
  border: 2px solid #ddd;
  border-radius: 4px;
  font-weight: 700;
  height: 50px;
  width: 43%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #97989d;
  padding-right: 15px;
  cursor: pointer;
}

.BoutonNonSelectionne:hover {
  background-color: #208ab010;
  color: #208ab0;
}

.BoutonSelectionne {
  border: 2px solid #208ab0;
  color: #208ab0;
  border-radius: 4px;
  height: 50px;
  width: 43%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  padding-right: 15px;
  cursor: pointer;
}
