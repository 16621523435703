.PageOptionsParametres {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0px solid blue;
}

.ContentPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
}

.MenuOptions {
  margin-right: 20px;
  width: 340px;
  border: 0px solid red;
}

.PartieDroite {
  width: 100%;
  display: flex;
  justify-content: center;
  border-left: 1px solid #ddd;
}

.FenetreOptions {
  position: relative;
  max-width: 1200px;
  width: 100%;
  border: 0px solid red;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .PageOptionsParametres {
    min-height: calc(100vh - 110px);
    width: 100%;
  }

  .MenuOptions {
    display: none;
  }

  .ContentPage {
    margin-top: 20px;
  }
}
