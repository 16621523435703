.Content {
  display: flex;
  gap: 40px;
  margin-top: 30px;
}

.EditionConstante {
  min-width: 50%;
  border: 0px solid red;
}

.ZoneSecondaire {
  width: 40%;
}
