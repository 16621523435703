.cabinet {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cabinet:hover {
  border-color: #208ab0;
  background-color: #208ab010;
  cursor: pointer;
}

.cabinetSelected {
  border: 1px solid #208ab0;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
