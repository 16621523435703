.Actualite {
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  width: 800px;
}
.Illustration {
  max-width: 100%;
  margin-top: 20px;
  width: 100%;
}

.Article {
  margin-bottom: 0px;
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  border-bottom: 4px solid #dddddd80;
  padding-bottom: 60px;
}

.Liens {
  border: 0px solid blue;
  gap: 30px;
  display: flex;
  flex-direction: row;
  font-family: Lato;
  font-weight: 500;
  width: fit-content;
  padding: 20px 0px;
  margin-bottom: 40px;
}

.Lien {
  font-size: 16px;
  color: #213945;
  text-decoration: none;
  padding: 0px;
  display: inline-block;
  padding-top: 13px;
  font-weight: 700;
}

.Lien:after {
  display: block;
  content: "";
  border-bottom: solid 2px #213945;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  margin-top: 5px;
}

.Lien:hover:after {
  transform: scaleX(1);
}

.Lien:hover {
  color: #213945;
  text-decoration: none;
  font-weight: 700;
}

.Illustration {
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.2);
}
