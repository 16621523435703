.EditionSupprimerRdv {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Choix {
  background-color: white;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 5px 0px;
  border: 1px solid white;
}

.Etiquette {
  display: flex;
  flex-direction: row;
}
