.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.Formulaire {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 40%;
  padding-bottom: 50px;
}

.Explications {
  width: 50%;
  margin-top: 20px;
  margin-bottom: 210px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Formulaire {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Explications {
    display: none;
  }
}
