.Page {
  width: fit-content;
  background-color: lightpink;
  position: relative;
}

.BarreOutils {
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 2;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
}

.BoutonsActions {
  display: flex;
  flex-direction: row;
}

.NumeroPage {
  position: absolute;
  bottom: 50px;
  background-color: #dddddd60;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BoutonsPage {
  background-color: #00000090;
  color: white;
  width: fit-content;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.TexteNumeroPage {
  margin: 0px 10px;
}
