.Ligne {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  border-top: 1px solid #ddd;
  border-radius: 0px;
  padding: 0px 20px;
}

.Utilisateur,
.Sms {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex: 2;
  border: 0px solid orange;
}

.Tarif {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border: 0px solid red;
  min-width: 30%;
  width: 30%;
  justify-content: flex-end;
  flex: 1;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .Ligne {
    padding: 10px 0px;
  }
  .Utilisateur {
    flex-direction: column;
    gap: 0px;
  }

  .Tarif {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    width: 30%;
    justify-content: space-between;
    align-items: center;
  }
}
