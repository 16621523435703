.PageSupport {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-height: calc(100vh - 70px);
}

.PartieGauche {
  border-right: 1px solid #ddd;
  min-height: calc(100vh - 72px);
  overflow-y: scroll;
  min-width: 320px;
  width: 40%;
  box-sizing: border-box;
}

.PartieDroite {
  min-height: calc(100vh - 72px);
  background-color: white;
  overflow-y: scroll;
  width: 60%;
  border: 1px solid #ddd;
}
