.Ligne {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ColonnePatient {
  width: 40%;
}

.ColonneTelephone {
  width: 25%;
}

.Rue {
  height: 20px;
  font-size: 16px;
  color: black;
}

.Ville {
  height: 20px;
  font-size: 14px;
  color: #666;
}

/********* MOBILE *************/

@media screen and (max-width: 840px) {
  .Ligne {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ColonnePatient {
    width: 100%;
  }
  .ColonneTelephone {
    display: none;
  }

  .ColonneAdresse {
    margin-left: 60px;
  }

  .Rue {
    height: 20px;
    font-size: 14px;
    color: #666;
  }
}
