.PageDocuments {
  padding: 20px;
  height: calc(100vh - 230px);
  overflow-y: scroll;
  background-color: white;
}

.EnteteEtBoutons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.ZoneRecherche {
  height: 40px;
  display: flex;
  flex-direction: row;
  margin: 40px 0px;
}

.InputRecherche {
  width: 300px;
  margin-right: 30px;
}

.ZoneResultats {
  margin-bottom: 400px;
  background-color: #f6f6f6;
  padding-bottom: 1px;
}

.LigneResultat {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
  width: 100%;
}

.EnteteDocuments {
  flex: 3;
  margin-left: 20px;
  margin-right: 0px;
}

.ColonneDocuments {
  flex: 3;
  margin-right: 20px;
  overflow: hidden;
}

.EnteteDate,
.ColonneDate {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  padding-right: 20px;
}

.ColonnePar {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ColonneTaille {
  flex: 1;
  margin-left: 10px;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-right: 20px;
  font-size: 14px;
}

.confimerSuppression {
  position: absolute;
  right: 0px;
  z-index: 10;
  background-color: #f6f6f6;
}

/********* TAILLE GRAND ECRAN *************/

@media screen and (min-width: 1200px) and (max-width: 1800px) {
  .ColonnePar {
    display: none;
  }
}

/********* TAILLE PETIT ECRAN *************/

@media screen and (min-width: 820px) and (max-width: 1200px) {
  .ColonnePar {
    display: none;
  }

  .ColonneTaille {
    display: none;
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .PageDocuments {
    padding: 20px;
    height: auto;
    overflow-y: unset;
    background-color: white;
  }

  .ZoneRecherche {
    height: 40px;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    min-height: 100px;
    justify-content: space-between;
    width: 100%;
  }

  .InputRecherche {
    width: 100%;
    margin-right: 0px;
  }

  .LigneResultat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-height: 30px;
  }

  .ColonneDocuments {
    width: 100%;
  }

  .ColonneDate {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-bottom: 10px;
    margin: -7px 0px 10px 72px;
  }

  .ColonnePar {
    display: none;
  }

  .ColonneTaille {
    display: none;
  }
}
