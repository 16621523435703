.PagePatient {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 0px solid black;
}

.Onglets {
  z-index: 1;
}

.Content {
  display: flex;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
  border: 0px solid red;
  min-height: calc(100vh - 120px);
}

.PageSynthese {
  min-width: 360px;
  max-width: 360px;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: #f2f1f5;
  border-radius: 15px;
  border: 1dvh solid white;
}

.PartieDroite {
  background-color: white;
  border-left: 0px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.ContenuPartieDroite {
  max-height: calc(100% - 50px);
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

/********* TAILLE PETIT ECRAN *************/

@media screen and (min-width: 820px) and (max-width: 1200px) {
}
/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PagePatient {
    width: 100%;
  }

  .PageSynthese {
    display: none;
  }

  .PartieDroite {
    border-radius: 0px 0px 0px 0px;
    background-color: #f6f6f6;
    border-left: none;
    width: 100%;
    overflow-y: scroll;
    max-width: 100%;
  }

  .AffichagePartieDroite {
    height: 100%;
    border: 10px solid red;
    box-sizing: border-box;
  }
}
