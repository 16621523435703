.LigneDemande {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  gap: 10px;
  width: 100%;
  background-color: white;
  margin: 2px 0px;
  cursor: pointer;
  padding: 5px;
}

.LigneDisponible {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  gap: 10px;
  width: 100%;
  background-color: #dddddd40;
  border: 1px dashed #00000030;
  margin: 2px 0px;
  cursor: pointer;
  justify-content: center;
  color: #97989d;
}

.Priorite {
  border-left: 3px solid red;
  height: 30px;
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
}
