.SuggestionInformationCabinet {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  min-width: 400px;
}

.Illustration {
  max-width: 40%;
}

.TexteEtBouton {
  flex: 1;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .SuggestionInformationCabinet {
    min-width: 0px;
  }
}
