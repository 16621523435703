.Bloc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow-wrap: normal;
  overflow: hidden;
}

.BoutonsAction {
  padding: 30px 0px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 20px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  flex-direction: column-reverse;
}

.Centre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
}
