.App {
  font-family: "Lato", sans-serif;
  background-image: radial-gradient(circle at 70% 40%, #218fb7 0%, #20343f 90%);
  overscroll-behavior-y: none;
  box-sizing: border-box;
  border: 0px solid red;
  color: black;
}

/************** LES COULEURS ***********/
:root {
  --noir80: #00000080;
  --noir50: #00000050;
  --noir20: #00000020;
  --bleu: #208ab0;
  --bleuDark: #205a71;
  --border: #ddd;
  --gris: #f6f6f6;
  --hover: #ededed;
  --vertSante: #0fa600;
}

/************** LES LIENS ***********/
a:link {
  text-decoration: none;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

/* a:hover {
  text-decoration: underline;
} */

a.BoutonLien {
  font-family: Lato;
  font-size: 16px;
  color: #208ab0;
  text-decoration: underline;
}

/************** LES BLOCS ***********/

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

nav {
  max-width: 320px;
  width: 100%;
  box-sizing: border-box;
}
/* NES bloc complet adresse, allergies etc...*/
section {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

article {
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
}

data {
  width: 100%;
  display: flex;
  flex-direction: column;
}

main {
  margin: 0;
}

/* TITRE PAGE */
hgroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
}

/************** LES ICONES ***********/
.IconeMui {
}

.Icone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
}
.IconeObjet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 30px;
  padding-right: 10px;
  padding-left: 10px;
}

.IconeMenu {
  margin-right: 15px;
  color: #666;
}

.Icone50px {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
}

.Icone30px {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
}

/************** LES POLICES ***********/

/* TITRE PAGE, ex: AGENDAS */
h1 {
  font-family: Lobster;
  font-size: 40px;
  color: black;
  font-weight: 500;
  margin: 0;
}

/* TITRE Papier, ex: Historique */
h2 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 30px;
  color: var(--bleuDark);
  margin: 0;
}

/* TITRE GROS BLOC, ex : Email, téléphone, adresse*/
h3 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 22px;
  color: black;
  margin: 0;
  margin-top: 20px;
}

/* TITRE PETIT BLOC, ex : Page synthèse patient : habitus, allergies, commentaires...*/
h4 {
  font-weight: 700;
  color: #97989d;
  font-family: Raleway;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
}

/* TITRE BLOC ANNEXE, ex : Page synthèse patient : habitus, allergies, commentaires...*/
/* TITRE POP UP */
h5 {
  font-family: Lobster;
  font-size: 30px;
  color: #205a71;
  font-weight: 300;
  margin: 0;
}

h6 {
  font-size: 18px;
  color: #205a71;
  font-weight: 700;
  margin: 0;
  font-family: Raleway;
}

p {
  font-family: Lato;
  text-align: justify;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  overflow-wrap: anywhere;
  color: black;
}

small {
  font-family: Lato;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

strong {
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: #213744;
}

mark {
  color: black;
  font-weight: 700;
  background: none;
  font-size: inherit;
}

label {
  font-weight: 700;
  color: #97989d;
  font-family: Raleway;
  font-size: 14px;
  margin: 0;
}

cite {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  border: 0px solid red;
  width: 100%;
  padding: 10px 0px;
  text-align: right;
  color: #00000080;
}

.Be {
  font-family: Lobster;
  font-weight: 500;
}

.TitrePop {
  font-family: Lobster;
  font-size: 30px;
  color: #213945;
}

.Titre1 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 30px;
  color: #205a71;
}

.Titre2 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 22px;
  color: black;
}

.TitreMobile {
  font-family: Raleway;
  font-weight: 300;
  font-size: 22px;
  color: #66666680;
}

.Titre2FondGris {
  font-family: Raleway;
  font-weight: 700;
  font-size: 22px;
  color: black;
  background-color: #f6f6f6;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.Titre2FondBleu {
  font-family: Raleway;
  font-weight: 700;
  font-size: 22px;
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-image: linear-gradient(153deg, #205a71 40%, #208ab0 100%);
}

.InformationsObligatoires {
  font-size: 12px;
  color: #666666;
}

.TitreBloc {
  font-weight: 700;
  color: #97989d;
  font-family: Raleway;
  font-size: 14px;
}

.SousTitre {
  font-family: Lato;
  font-weight: 500;
  font-size: 16px;
  color: #97989d;
  margin: 0;
}

.InformationPrincipaleImportante {
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: #213744;
}

.InformationPrincipale {
  font-family: Lato;
  font-weight: 500;
  font-size: 16px;
  color: black;
  overflow-wrap: anywhere;
}

.InformationSecondaire {
  font-family: Lato;
  color: rgb(151, 152, 157);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.InformationNonLue {
  font-family: Lato;
  font-weight: 900;
  font-size: 17px;
  color: #000000;
}

.Bcb {
  font-family: Lato-Italic, Lato;
  font-size: 12px;
  color: #08aa08;
  margin-top: -2px;
}

.Message {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  color: #66666680;
  text-align: center;
}

.Label {
  font-size: 12px;
  color: #00000080;
  letter-spacing: -0.02em;
}

.Lien {
  text-decoration: underline;
  color: #208ab0;
  cursor: pointer;
}

.Signature {
  font-size: 30px;
  font-family: "Caveat";
  color: #205a71;
  text-align: right;
  width: 70%;
}

.NomProduit {
  font-family: Lobster;
  font-weight: bolder;
}

.MobileOnly {
  display: none;
}

/********* AFFICHAGE LISTE *************/
.ListeLigne {
  min-height: 60px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 0px;
  box-sizing: border-box;
}

/********* IMAGES *************/

img {
  max-width: 100%;
}

.Illustration {
  max-width: 100%;
}

/********* TAILLE MOYEN ECRAN *************/
@media screen and (min-width: 1200px) and (max-width: 1800px) {
}

/********* TAILLE PETIT ECRAN OU TABLETTE *************/
@media screen and (min-width: 820px) and (max-width: 1200px) {
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .App {
  }

  .HideMobile {
    display: none;
  }

  .MobileOnly {
    display: block;
  }

  .TitreMobile {
    font-family: Lobster;
    font-size: 40px;
    color: #213945;
  }
  .ListeLigne {
    min-height: 60px;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 0px;
  }
}
