.AffichageAlerteCarteBancaire {
  border: 0px solid pink;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px;
}

.Message {
  border: 0px solid green;
}
