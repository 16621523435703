.BoutonActionFlottant {
  position: absolute;
  position: sticky;
  position: -webkit-sticky;
  bottom: 60px;
  transform: scale(0);
  animation: load 0.5s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  width: 60px;
  right: 30px;
  margin-left: calc(100% - 100px);
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .BoutonActionFlottant {
    position: -webkit-sticky;
    position: sticky;
    bottom: 30px;
    margin-left: calc(100% - 75px);
  }
}

/********* ANIMATION *************/

@keyframes load {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
