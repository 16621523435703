.CaseHoraire {
  width: 100%;
  border-bottom: 1px solid #dddddd50;
  box-sizing: border-box;
}

.Case {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  opacity: 1;
}

.Case:hover {
  background-color: #205a71;
  opacity: 0.9;
  color: white;
  border-radius: 3px;
}

.SeparateurHeure {
  height: 2px;
}

.HeureRdv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}
