.SeparateurBloc {
  min-height: 50px;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 700;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: black;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .SeparateurBloc {
    min-height: 40px;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    padding-left: 0px;
    background-color: #205a7120;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #205a71;
  }
}
