.ListePriorite {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PrioriteDemande {
  border: 2px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 8px;
}

.PrioriteDemande:hover {
  border: 2px solid #208ab0;
}

.Delai {
  color: #666;
  font-size: 14px;
}
