.PartieGauche {
  background-color: #21394520;
  width: 240px;
  padding: 15px;
  box-sizing: border-box;
  overflow: scroll;
}

.EditionRdv {
  width: 400px;
  padding: 20px 20px 0px 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  border: 0px solid red;
  min-height: 650px;
  height: 700px;
  display: flex;
  flex-direction: column;
}

.TitreEtBouton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  border: 0px solid green;
  margin-bottom: 20px;
}

.View {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 0px solid blue;
  height: 600px;
}

.Bloc {
  height: 50px;
  display: flex;
  align-items: center;
}

.IconeBloc {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 7px;
}
.DataBloc {
  width: 100%;
  margin-left: 15px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PartieGauche {
    display: none;
  }
}
