.PopupDeconnexionAuto {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Logo {
  width: 100px;
}

.Temps {
  font-size: 100px;
  margin-top: 50px;
}

.Explications {
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 300;
}

.Conseil {
  margin-top: 50px;
  font-size: 24px;
  font-weight: 700;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .PopupDeconnexionAuto {
    width: 80%;
  }
}
