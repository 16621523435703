.NomAgendaMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  color: white;
  font-weight: 700;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
  display: none;
}

@media screen and (max-width: 820px) {
  .NomAgendaMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    width: 100%;
    color: white;
    font-weight: 700;
    font-size: 20px;
    box-sizing: border-box;
    border-radius: 0px 0px;
  }
}
