.TitreEtBouton {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.colonneCabinet {
  width: 100%;
}

.ListeCabinets {
  border-top: 1px solid #ddd;
  padding: 0px;
}

.Ligne {
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.Ligne:hover {
  background-color: #dddddd30;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .TitreEtBouton {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .BoutonActionSecondaire {
    margin-top: 20px;
  }
}
