.ObjetCommentaire {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.Bulle {
  margin-left: 0px;
  background-color: white;
  border-radius: 15px 10px 10px 0px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.BullePersonnel {
  margin-left: 0px;
  background-color: #ff9800;
  border-radius: 15px 10px 10px 0px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.TextePersonnel {
  font-size: 14px;
  font-weight: 500;
  color: #ff9800;
  height: 40px;
  display: flex;
  align-items: center;
}

.BasCommentaire {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}
