.PanneauListeMesures {
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 400px;
  max-width: 500px;
}

.ListeMesures {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
