.BarreMenuAgenda {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  box-sizing: border-box;
  background-color: white;
  min-height: 50px;
}

.AffichageDates {
  flex: 1;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.BoutonsActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BarreMenuAgenda {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 0px 0px;
    box-sizing: border-box;
    background-color: white;
    min-height: 10px;
    margin-bottom: 0px;
  }

  .BarreOutilAgenda {
    display: none;
  }

  .AffichageDates {
    flex: 1;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .BoutonsActions {
    display: none;
    flex-direction: row;
    align-items: center;
  }
}
