.SuggestionAjouterUtilisateur {
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

.Illustration {
  max-width: 300px;
}

.Textes {
  max-width: 300px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .SuggestionAjouterUtilisateur {
    min-width: 0px;
    max-width: 100%;
    flex-direction: column;
  }
}
