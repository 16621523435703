.Entete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.RappelPatient {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0px;
  justify-content: center;
}

.BoutonsActions {
  height: 60px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.EditionMotifConsultation {
  margin-top: 0px;
}

.Examen,
.Mesures,
.Documents,
.Bloc {
  margin-top: 30px;
}

.BlocPoulLangue {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.BlocPouls,
.BlocLangue {
  flex: 1;
  max-width: 48%;
  min-width: 200px;
}

.Diagnostic {
  margin-top: 10px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BlocPouls,
  .BlocLangue {
    max-width: 100%;
    min-width: 300px;
    margin-top: 20px;
  }
  .BlocPoulLangue {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
