.OngletActif {
  background-color: #205a71;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex: 1;
  cursor: pointer;
  min-height: 100%;
}

.Onglet {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #205a71;
  flex: 1;
  cursor: pointer;
  min-height: 100%;
}
.Onglet:hover {
  background-color: #205a7110;
}
