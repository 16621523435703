.TitreEtBouton {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.TitreDescription {
  border: 0px solid orange;
  max-width: 60%;
}

.Libelle {
  width: 100%;
  flex: 2;
  box-sizing: border-box;
  border: 0px solid orange;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 40px;
  color: black;
  border: 0px solid blue;
}

.TagProduit {
  border: 0px solid red;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .TitreEtBouton {
    flex-direction: column;
  }

  .BoutonActionSecondaire {
    margin-top: 30px;
    width: 100%;
  }

  .Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 0px solid black;
  }

  .TagProduit {
    margin-left: 0px;
    margin-top: 6px;
    margin-bottom: 5px;
    width: 100%;
  }
}
