.PageListeAgendas {
  padding: 0px;
  border: 0px solid red;
}

.Titre {
  font-weight: 700;
  color: #97989d;
  font-family: Raleway;
  font-size: 14px;
}

.TousAgendas {
  border-top: 1px #dddddd solid;
  padding-top: 20px;
  color: #213945;
  display: none;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .PageListeAgendas {
    padding: 20px;
  }

  .Titre {
    font-family: Raleway;
    font-weight: 700;
    font-size: 30px;
    color: #205a71;
    margin-bottom: 20px;
  }

  .TousAgendas {
    display: none;
  }
}
