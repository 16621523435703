.BarreOngletPatient {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 0px;
  box-sizing: border-box;
  margin-bottom: -1px;
}

.styleOngletActif {
  position: relative;
  background: white;
  box-shadow: -4px -4px 4px 0 rgba(0, 0, 0, 0.5);
  height: 50px;
  min-width: 30px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0px 5px 0px 10px;
  z-index: 2;
  margin: 0px 2px;
  border-right: 1px #ffffff20 solid;
  /*border-bottom: 1px #FFFFFF00 solid;*/
  border-bottom: 0px #f6f6f6 solid;
}

.styleOngletLoading {
  position: relative;
  background: white;
  box-shadow: -4px -4px 4px 0 rgba(0, 0, 0, 0.5);
  height: 50px;
  min-width: 30px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0px 5px 0px 10px;
  z-index: 2;
  margin: 0px 2px;
  border-right: 1px #ffffff20 solid;
  /*border-bottom: 1px #FFFFFF00 solid;*/
  border-bottom: 0px #f6f6f6 solid;
  transition-delay: 250ms;
  transition-property: margin-right;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari et Chrome */
  -o-animation: fadein 2s; /* Opera */
}

.styleOngletInactif {
  position: relative;
  background: none;
  box-shadow: none;
  height: 50px;
  min-width: 30px;
  border-radius: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0px 5px 0px 10px;
  z-index: 1;
  color: white;
  border-right: 1px #ffffff20 solid;
  margin: 0px 2px;
  border-bottom: 0px #f6f6f6 solid;
}

.styleOngletInactif:hover {
  background-color: #ffffff20;
  border-radius: 8px 8px 0px 0px;
}

.BoutonAjouter {
  margin-left: 10px;
}

/********* MOBILE *************/

@media screen and (max-width: 750px) {
  .BarreOngletPatient {
    top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0px;
    z-index: 100;
    padding: 0px 10px;
    margin-top: 10px;
  }

  .styleOngletActif {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    margin: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    position: sticky;
    z-index: 5;
    border-bottom: 1px solid #dddddd;
    border-right: none;
    padding-bottom: 1px;
    height: 60px;
    opacity: 0.95;
    background: #fefefe;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  }

  .styleOngletInactif {
    display: none;
  }

  .BoutonAjouter {
    display: none;
  }

  .OngletPatientsRecents {
    display: none;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
