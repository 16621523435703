.PageDossier {
  padding: 40px 20px;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1600px;
  width: 100%;
}

.Formulaire {
  min-width: 300px;
  max-width: 500px;
  width: 45%;
}

.CommentairesEtFamille {
  min-width: 300px;
  max-width: 700px;
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.ObjetTracabilite {
  margin-top: 60px;
}

/********* TAILLE PETIT ECRAN *************/

@media screen and (min-width: 820px) and (max-width: 1200px) {
  .PageDossier {
    padding: 40px 10px;
    height: calc(100vh - 170px);
    overflow-y: scroll;
    box-sizing: border-box;
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .PageDossier {
    padding: 0px;
    padding-bottom: 60px;
    height: 100%;
  }

  .Wrapper {
    flex-direction: column;
    align-items: center;
  }

  .Formulaire {
    padding: 40px 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .CommentairesEtFamille {
    width: 100%;
  }
  .ObjetTracabilite {
    margin-top: 0px;
  }
}
