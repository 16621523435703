.AffichageMesure_L {
  background-color: #f6f6f6;
  height: 60px;
  border: 1px solid #dddddd;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AffichageMesure {
  background-color: #dddddd50;
  height: 25px;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding-right: 10px;
  padding-left: 5px;
  margin-right: 8px;
  margin-top: 8px;
}

.Icones {
  width: 25px;
}

.Mesure {
  font-size: 12px;
  font-weight: 700;
  /*color: black;*/
}

.Unite {
  font-size: 12px;

  /*color: #666; */
  margin-left: 4px;
}
