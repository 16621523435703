.PageVerification {
  display: flex;
  flex-direction: row;
}

.PartieDroite {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
}

.Formulaire {
  width: 400px;
  margin-top: 80px;
}
