.Menu {
  width: 70px;
  min-width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 80px;
  height: fit-content;
  box-sizing: border-box;
  padding-bottom: 70px;
  border: 0px solid yellow;
}

.IconeMenu {
  margin-top: 20px;
}

.IconeMenuSticky {
  margin-top: 20px;
  z-index: 2000;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Menu {
    display: none;
  }
}
