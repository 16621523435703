.MessageErreur {
  position: relative;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  width: 100%;
}

.DetailError {
  border-top: 1px solid white;
  margin-top: 20px;
  padding-top: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.TexteError {
  font-size: 12px;
  font-family: Lato;
  font-weight: 300;
  box-sizing: border-box;
}

@keyframes shake {
  0% {
    opacity: 0;
  }

  10%,
  90% {
    transform: translate3d(-4px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }

  100% {
    opacity: 1;
  }
}
