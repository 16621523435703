/**************** BARRE D'OUTIL ************************/
.BarreOutilsMobile {
  position: sticky;
  width: 100%;
  height: 75px;
  bottom: 0px;
  opacity: 0.95;
  background: #fefefe;
  box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.15);
  z-index: 50;
  padding-top: 5px;
  display: none;
  box-sizing: border-box;
}

.ZoneDisponible {
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  font-size: 11px;
}

/********* DESKTOP *************/

@media screen and (max-width: 820px) {
  .BarreOutilsMobile {
    display: block;
  }
}
