.EnteteFacture {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Boutons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 15px;
}

.Content {
  margin-top: 15px;
}

.TitreBlocCotation {
  color: white;
  font-weight: bold;
  background-color: #205a71;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}

.Ligne {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 5px 15px;
}

.LigneTotal {
  border-top: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
}

.Parts {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.Remise {
  color: #205a71;
  font-size: 14px;
  font-weight: 700;
}

.LibelleAvoir {
  font-weight: lighter;
  color: #666;
}
