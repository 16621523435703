.BlocDateRdv {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.LibelleDate {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Choix {
  width: 70px;
  display: flex;
  justify-content: flex-end;
}
