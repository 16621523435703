.Cotation {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
}

.CotationSelected {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #208ab0;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.AucunePrestation {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  align-items: center;
}

.EnteteCotation {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 0px 10px;
  border-radius: 8px 8px 0px 0px;
  color: #97989d;
}
.EnteteCotationSelected {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #208ab010;
  padding: 0px 10px;
  border-radius: 8px 8px 0px 0px;
  color: #208ab0;
}

.TitreConsultation {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Raleway;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
}

.EnteteColonnes {
  display: flex;
  font-size: 14px;
  color: #666666;
  text-align: right;
  padding-right: 40px;
}

.ListePrestations {
  padding: 10px 20px 0px;
}

.LignePrestation {
  min-height: 50px;
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ColonnePrestation {
  width: 80%;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ColonneMontant {
  width: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  min-width: 80px;
  font-size: 14px;
}

.ColonneRemise {
  width: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  min-width: 80px;
  font-size: 14px;
  cursor: pointer;
  color: #208ab0;
}

.TotalPrestations {
  display: flex;
  font-size: 16px;
  font-weight: 900;
  color: black;
  text-align: right;
  padding-right: 40px;
  height: 50px;
  align-items: center;
  border-top: 1px solid black;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .EnteteCotation {
    padding: 0px 10px;
  }
  .EnteteCotationSelected {
    padding: 0px 10px;
  }
  .ListePrestations {
    padding: 5px 0px;
  }
  .ColonnePrestation {
    padding-left: 10px;
  }
}
