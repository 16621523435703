.DayPicker {
  height: 50px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Jour {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  font-family: Raleway;
  color: #97989d;
  font-weight: 700;
  border-radius: 25px;
  font-size: 16px;
}

.Jour:hover {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dddddd60;
  font-family: Raleway;
  color: #97989d;
  font-weight: 700;
  border-radius: 25px;
  cursor: pointer;
}

.JourSelectionne {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #0fa600;
  font-family: Raleway;
  color: white;
  font-weight: 700;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}
