.AffichageEvenement {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  opacity: 1;
  border-radius: 4px;
  width: calc(95% - 10px);
  border: 1px solid white;
  padding-left: 6px;
  padding-right: 4px;
  padding-top: 2px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
  position: absolute;
  color: white;
  text-transform: capitalize;
  box-sizing: border-box;
  cursor: pointer;
}

.NomCompletEvenement {
  padding-top: 2%;
  width: 100%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: row;
}

.HeureEvenement {
  padding-top: 2%;
  width: 100%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

.AffichageEvenement:hover {
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 10;
}
