.SurfaceBlocButton {
  cursor: pointer;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
}

.SurfaceBlocButton:hover {
  border: 1px solid #208ab0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.IconeIllustration {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  opacity: 0.15;
  z-index: 1;
  border: none;
}

.Children {
  z-index: 2;
  position: relative;
}
