.SurfaceObjetSelectionnable {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #97989d;
}

.Selected {
  padding: 10px;
  border: 1px solid #208ab0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.SurfaceObjetSelectionnable:hover {
  border: 1px solid #208ab0;
  color: #208ab0;
}
