.EditionAvatar {
  position: relative;
}

.BoutonChanger {
  background-color: #000000;
  opacity: 0;
  color: white;
  position: absolute;
  top: 0px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.BoutonChanger:hover {
  background-color: #00000090;
  color: white;
  position: absolute;
  opacity: 0.9;
  top: 0px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
