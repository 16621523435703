.EditionTypeRdv {
  margin-top: 15px;
  /*max-height: 370px;*/
  overflow-y: scroll;
}

.TypeRdv {
  background-color: white;
  width: 100%;
  background-color: yellow;
  padding-right: 15px;
  padding-left: 0px;
  border: 1px solid red;
  padding: 0px;
}
