.BoutonCapture {
  border: 0px solid red;
  width: fit-content;
  position: sticky;
  z-index: 4000;
  top: calc(100vh - 120px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PopUp {
  display: flex;
  flex-direction: row;
  min-width: 800px;
  border: 0px solid green;
  height: 80vh;
}

.PageDemande {
  display: flex;
  flex-direction: row;
  width: 800px;
  padding: 30px 30px 10px 30px;
  box-sizing: border-box;
  border: 0px solid blue;
}

.Capture {
  border: 0px solid red;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Image {
  border: 1px solid #ddd;
  box-shadow: 2px 2px 4px #00000050;
}

.PartieGauche {
  width: 400px;
  padding: 30px 30px;
  box-sizing: border-box;
}

.PartieDroite {
  overflow: scroll;
  margin-top: 0px;
  width: 400px;
  box-sizing: border-box;
  background-color: #f6f6f6;
  border-radius: 0px 6px 6px 0px;
  border-left: 1px solid #ddd;
  padding: 30px;
  border: 0px solid red;
}

.ListeDemandes {
  margin-top: 20px;
}

.Image {
  width: 100%;
  max-height: 30vh;
  border: 0px solid green;
  overflow: hidden;
  margin-top: 20px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BoutonCapture {
    display: none;
  }
}
