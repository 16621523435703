.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  box-sizing: border-box;
}

.Content {
  max-height: 80vh;
  overflow: scroll;
  min-width: 500px;
  max-width: calc(100vw - 80px);
  box-sizing: border-box;
}

.Titre {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.TitreDisabledPadding {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 0px;
  border: 0px solid pink;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .SurfacePapier {
    max-width: 90vh;
  }

  .Content {
    max-height: 80vh;
    overflow: scroll;
    min-width: calc(100vw - 60px);
    width: calc(100vw - 60px);
    max-width: calc(100vw - 60px);
  }
}
