.PopupChoixRole {
  width: fit-content;
}

.ListeRoles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  box-sizing: border-box;
  margin-top: 20px;
}
.Role {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.Tarif {
  margin: 20px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .ListeRoles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    box-sizing: border-box;
    margin-top: 0px;
  }
}
