.SuggestionAjouterTarif {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.TexteEtBouton {
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Illustration {
  width: 300px;
}
