.Posologie {
  height: 60px;
  width: 60px;
  background: #f6f6f680;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
}
.Posologie:hover {
  background: #dddddd70;
}

.Qte {
  color: #208ab0;
  font-size: 16px;
  font-weight: 900;
}

.Moment {
  color: #97989d;
  font-weight: 400;
  font-size: 12px;
}

.IconeMoment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.EditionPosologie {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
