.PageEditionProduit {
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
}

.BlocLaboratoire {
  margin-top: 40px;
  margin-bottom: 40px;
}

.BlocProduit {
  margin-top: 20px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
}
