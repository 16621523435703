.AffichageMesuresSuivies {
  margin-top: 20px;
  width: 100%;
}

.Mesures {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
}
