.MessageErreur {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.InfoMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 0px solid blue;
  flex: 1;
}

.InfoErreur {
  border: 0px solid red;
  max-width: calc(100% - 50px);
}

.Message {
  border: 0px solid pink;
  max-width: 100%;
  font-weight: bold;
}

.Url {
  font-size: 14px;
  word-wrap: break-word;
  color: #97989d;
  color: #208ab0;
}

.Error {
  font-size: 14px;
}

.InfoUtilisateur {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: 0px solid green;
}

.IconeMateriel {
  min-width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid yellow;
  margin-right: 10px;
}

.Textes {
  border: 0px solid red;
  max-width: 100%;
  width: 100%;
}

.DateHeure {
  font-size: 14px;
  color: #000000;
}

.Organisation,
.Utilisateur {
  font-size: 14px;
  color: #00000080;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .MessageErreur {
    flex-direction: column;
  }

  .InfoUtilisateur {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border: 0px solid green;
    padding-left: 50px;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .DateHeure,
  .Organisation,
  .Utilisateur {
    font-size: 14px;
    color: #00000080;
  }
}
