.BlocAdresse {
  display: flex;
  align-items: center;
  height: 50px;
}

.AdresseEtIcone {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.Adresse {
  color: #66666680;
}
