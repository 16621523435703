.AffichageSyntheseConsultation {
  display: flex;
  flex-direction: row;
  padding: 15px 15px 15px 0px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
  min-height: 90px;
  height: fit-content;
}

.TypeConsultation {
  min-width: 2px;
  max-width: 2px;
  border-radius: 2px;
  margin-left: 0px;
  margin-right: 10px;
}

.ZoneIcone {
  min-width: 30px;
  width: 30px;
  max-width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50px;
}

.Icone {
  width: 14px;
  height: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 11px;
}

.Affichage {
  width: 100%;
}

.Entete {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.MotifConsultation {
  color: #0a0a0a;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #000000;
  font-weight: 700;
  display: block;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DateConsultation {
  color: #666;
  font-size: 15px;
  margin-left: 10px;
}

.Description {
  color: #666;
  font-size: 15px;
  font-weight: 500;
  display: block;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.Diagnostic {
  color: black;
  font-size: 15px;
  font-weight: bolder;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.AfficherConstantes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.BilanEnergetique {
  font-size: 14px;
  color: #1c721b;
  margin-bottom: 8px;
}
