.AppNonDisponible {
  display: flex;
  flex-direction: row;
}

.Nom {
  font-size: 60px;
  text-transform: capitalize;
}

.NonDisponible {
  font-size: 24px;
  font-weight: 700;
}
.Titre {
  font-family: Lobster;
  font-size: 40px;
  color: white;
}

.Titre2 {
  font-family: Raleway;
  font-size: 24px;
  color: white;
  font-weight: 900;
}

.illustrationErreur {
  max-width: 100%;
  height: auto;
}

.SurfacePapier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Description {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
}

.Delai {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: white;
  border: 1px solid #ddd;
  margin-bottom: 50px;
}
