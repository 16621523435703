.TitreEtBouton {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  box-sizing: border-box;
}

.ZoneRecherche {
  display: flex;
  flex-direction: row;
  margin: 30px 0px;
  align-items: center;
}
.InputRecherche {
  width: 300px;
  margin-right: 30px;
}

.Resultats {
  flex: 1;
  border: 0px solid blue;
}

.Ligne {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  margin: 2px 0px;
  min-height: 60px;
  padding: 20px;
  border: 0px solid orange;
  gap: 20px;
}

.Ligne:hover {
  background-color: var(--hover);
  cursor: pointer;
}

.ContenuLigne {
  width: 100%;
  display: flex;
  align-items: center;
}

.ColonneProduit {
  flex: 3;
  padding-right: 20px;
}

.ColonnePrix {
  width: 100px;
  text-align: right;
  padding-right: 20px;
  font-weight: 900;
  font-size: 16px;
  color: #666666;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .ZoneRecherche {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
    justify-content: center;
  }
  .InputRecherche {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .ColonneProduit {
    flex: 3;
    padding-right: 20px;
  }

  .ColonnePrix {
    width: 100px;
    text-align: right;
    padding-right: 20px;
    font-weight: 900;
    font-size: 16px;
    color: #666666;
    display: none;
  }
}
