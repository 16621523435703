.PageUtilisateurs {
  min-height: 600px;
}

.Entete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.IllustrationEquipe {
  width: 60%;
}

.TitreExplications {
  width: 60%;
}

.ListeUtilisateurs {
  margin-top: 40px;
  border-top: 1px solid #ddd;
  padding: 0px;
  box-sizing: border-box;
}

.Ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 0px;
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
}

.Ligne:hover {
  background-color: #dddddd30;
}

.ContenuLigne {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 50px);
}

.ColonneUtilisateur {
  flex: 2;
}

.ColonneTag {
  flex: 1;
}

.Explications {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  min-height: 200px;
  margin-bottom: 20px;
}

.Illustration {
  flex: 1;
}

.TexteExplications {
  flex: 1;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Entete {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .IllustrationEquipe {
    display: none;
  }

  .TitreExplications {
    width: 100%;
  }

  .LigneTableau {
    height: 90px;
  }

  .ContenuLigne {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ColonneTag {
    width: 100%;
    padding-left: 55px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
