/********* ENTETE AGENDA *************/
.ListeJours {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  box-sizing: border-box;
  background-color: white;
}

.ColonneEnteteAgenda {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 70px;
  align-items: center;
  justify-content: center;
}

.NumeroJour {
  font-size: 20px;
  color: #97989d;
  font-weight: 700;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.NumeroJourAujourdhui {
  font-size: 20px;
  color: #205a71;
  font-weight: 700;
  background-color: #205a7110;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: scale(0);
  animation: load 0.5s;
  animation-fill-mode: forwards;
}

.NumeroJourSelectionne {
  font-size: 20px;
  color: white;
  font-weight: 700;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: scale(0);
  animation: load 0.5s;
  animation-fill-mode: forwards;
  background-color: #205a71;
}

.NomJour {
  font-size: 16px;
  color: #97989d;
}

.NomJourAujourdhui {
  font-size: 16px;
  color: #205a71;
}

.NomMoisMobile {
  margin-top: 10px;
  text-transform: capitalize;
  font-family: Raleway;
  font-weight: 700;
  display: none;
}

/********* ANIMATION *************/

@keyframes load {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .ListeJours {
    padding-left: 0px;
    position: sticky;
    top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid #ddd;
  }

  .NumeroJour {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  .NumeroJourAujourdhui {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  .NumeroJourSelectionne {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  .NomJour {
    font-size: 14px;
  }

  .NomJourAujourdhui {
    font-size: 14px;
  }
  .NomMoisMobile {
    display: block;
  }
}
