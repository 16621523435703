.content {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}

.Formulaire {
  display: flex;
  max-width: 340px;
  min-width: 340px;
  flex-direction: column;
  box-sizing: border-box;
}
.partieDroite {
  width: 45%;
}

.listeUtilisateurs {
  margin-top: 20px;
}
.utilisateur {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #dddddd50;
  background-color: white;
}

.utilisateur:hover {
  border: 1px solid #208ab0;
  background-color: #208ab010;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
}

.bloc {
  margin-bottom: 40px;
}

.BlocEnregistrer {
  width: 100%;
  padding: 0px 40px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  box-sizing: border-box;
}

/********* TAILLE PETIT ECRAN *************/
@media screen and (min-width: 820px) and (max-width: 1200px) {
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
  }

  .Formulaire {
    width: 100%;
    max-width: 100%;
    min-width: 200px;
  }

  .partieDroite {
    width: 100%;
  }

  .BlocEnregistrer {
    padding: 0px 0px;
  }
}
