.ObjetReglement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.IconeEtTexte {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TexteReglementEtCotation {
  display: flex;
  flex-direction: column;
}

.TexteReglement {
  display: flex;
  flex-direction: column;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
}
