.PageApdxErreurs {
  padding: 30px;
}

.Filtres {
  background-color: #dddddd20;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px 0px;
}

.Ligne {
  background-color: white;
  padding: 10px;
  margin-bottom: 2px;
}

.GraphiqueBarre {
  border: 0px solid blue;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageApdxErreurs {
    padding: 15px;
  }

  .Filtres {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0px;
  }

  .GraphiqueBarre {
    display: none;
  }
}
