.Panneau {
  padding: 20px;
  min-width: 340px;
}

.EntetePanneau {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.Total {
  background-color: #dddddd50;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
}
