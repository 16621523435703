.ObjetTracabilite {
  width: 100%;
  min-height: 40px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 0px;
}

.TexteEtSupprimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.Texte {
  margin-right: 40px;
  font: Lato;
  font-weight: 500;
}

.ConfirmationSuppression {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.MessageSuppression {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-right: 20px;
}

@media screen and (max-width: 820px) {
  .TexteEtSupprimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Texte {
    margin-top: 20px;
    margin-right: 0px;
    text-align: center;
  }
}
