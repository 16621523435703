.SurfacePage {
  width: calc(100%);
  padding: 30px 20px 80px 30px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .SurfacePage {
    width: calc(100%);
    padding: 0px;
    max-width: 100%;
    padding: 15px;
  }
}
