.EditionRemise {
  width: 300px;
}

.Formulaire {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0px 10px 0px;
}

.Champ {
  flex: 1;
}

.Label {
  color: #666;
}

.Montant {
  color: black;
  font-weight: 700;
}

.Detail {
  margin-bottom: 20px;
  margin-top: 20px;
}

.Erreur {
  color: red;
  margin-bottom: 10px;
}

.Prestation {
  font-weight: 700;
}
