.TextArea {
  padding: 10px;
  font-family: Lato;
  font-size: 16px;
  color: #208ab0;
  resize: none;
  width: calc(100% - 22px);
  outline: none;
  border: 1px solid #ffffff00;
  background-color: #ffffff00;
  border-radius: 4px;
  -webkit-appearance: none;
}

.TextArea:focus {
  border: 1px solid #208ab0;
  background-color: white;
}

.TextArea:disabled {
  color: black;
  font-weight: 500;
}
