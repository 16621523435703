.MessageOnboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Illustration {
  width: 300px;
  margin-bottom: 20px;
}
