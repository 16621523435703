.ObjetPatient {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.ObjetPatientS {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 3px;
}

.ObjetPatientL {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  gap: 6px;
  height: 50px;
  position: relative;
  color: inherit;
  box-sizing: border-box;
  border: 0px solid red;
}

.PatientL {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
}

.NomL {
  font-size: 20px;
  font-weight: bolder;
  color: inherit;
}

.PrenomL {
  font-weight: 300;
}

.SecondaireL {
  font-size: 14px;
  opacity: 0.5;
  margin-top: -2px;
}

.InformationPrincipale {
  height: 20px;
  font-family: Lato;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: black;
}

.InformationPrincipaleArchive {
  height: 20px;
  font-family: Lato;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #333;
  text-decoration: line-through;
}

.Prenom {
  font-weight: 300;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SexeAge {
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InformationSecondaire {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.Archive {
  color: white;
  background-color: #333;
  padding: 2px 10px;
  font-weight: 500;
  border-radius: 20px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Nom {
    text-transform: uppercase;
    font-weight: 500;

    margin-right: 5px;
    text-overflow: ellipsis;
  }

  .Prenom {
    text-transform: capitalize;
    text-overflow: ellipsis;
  }
}
