.PageJournalDesFactures {
  display: flex;
  flex-direction: row;
}

.Periodes {
  min-width: 320px;
  box-sizing: border-box;
  padding-right: 15px;
}

.ListeFactures {
  width: 100%;
}

/********* TAILLE PETIT ECRAN OU TABLETTE *************/
@media screen and (min-width: 820px) and (max-width: 1200px) {
  .Periodes {
    display: none;
  }
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
}
