.PageConsultation {
  width: 70%;
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 175px);
  box-sizing: border-box;
  align-items: center;
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  border: 0px solid red;
}

.Consultation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  /* height: calc(100vh - 230px);
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box; */
  border: 0px solid green;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
}

.BlocPages {
  max-width: 1000px;
  width: 100%;
  padding: 0px 20px;
  border: 0px solid red;
  padding-bottom: 80px;
}

.BarreActionHaut {
  top: 0px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  padding-left: 20px;
  padding-top: 0px;
  border: 0px solid blue;
  position: sticky;
  opacity: 0.9;
  width: 100%;
  box-sizing: border-box;
}

.BoutonRetour {
  display: none;
}
/********* PETIT ECRAN *************/
@media screen and (min-width: 820px) and (max-width: 1340px) {
  .PageConsultation {
    width: 100%;
  }

  .BoutonRetour {
    display: block;
  }
  .BarreActionHaut {
    background-color: white;
  }
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BoutonRetour {
    display: block;
  }
  .PageConsultation {
    width: 100%;
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
    margin-right: 00px;
    overflow-y: unset;
  }

  .Consultation {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px 0px;
  }
}
