.InputSelectObjet {
  min-height: 40px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  color: #777;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin-top: 10px;
  background-color: white;
}

.InputSelectObjet:hover {
  cursor: pointer;
}

.InputSelectObjetDisabled {
  min-height: 40px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #ededed;
  color: black;
}

.ObjetNonSelectionne {
  flex: 1;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.ObjetSelectionne {
  flex: 1;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  color: #208ab0;
}

.Liste {
  min-width: 300px;
  box-sizing: border-box;
  border-top: 1px solid #ddd;
  padding: 0px 0px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.Objet {
  min-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  margin: 2px;
  padding: 0px 15px;
}
