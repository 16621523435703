.PageApdxPlanning {
  padding: 20px 30px 100px 30px;
}

.ListeDemandes {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
}

.PageDemande {
  width: 800px;
  padding: 0px;
  border: 0px solid red;
  box-sizing: border-box;
  min-height: 100vh;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageApdxPlanning {
    padding: 15px;
  }
  .PageDemande {
    width: 100vw;
    padding: 0px;
    min-height: 100vh;
  }
}
