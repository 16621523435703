.ObjetHabitus {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
}
