.AffichagePatient {
  box-sizing: border-box;
  /*background-color: yellow;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 0px solid orange;
}

.AffichagePatient:hover {
  border-radius: 5px;
  cursor: pointer;
}

.NomPrenom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: black;
}

.Nom {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 5px;
  font-size: 40px;
}

.NomJeuneFille {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 40px;
  margin-right: 5px;
}

.Prenom {
  font-family: Lato;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 40px;
  color: #333;
}

.InformationsSecondaires {
  font-family: Lato;
  font-weight: 500;
  font-size: 14px;
  color: #666;
  text-justify: center;
  text-align: center;
  font-size: 16px;
}

.ListeTelephones {
  margin-top: 20px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.BlocCommentaires {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 20px;
}

.ListeCommentaires {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.ObjetTelephone {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #205a7110;
  margin-top: 4px;
  box-sizing: border-box;
}

.Famille {
  width: 100%;
}
