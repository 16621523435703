.SurfaceLigne {
  border-top: 1px solid #ddd;
  padding: 10px;
}

.SurfaceLigne:hover {
  background-color: var(--hover);
  border-radius: 4px;
  cursor: pointer;
}
