.PageListeDemandes {
  padding: 20px;
}

.AucuneDemande {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
}
