.CodeConsultationPicker {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.CodeConsultation {
  border: 0px solid green;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 340px;
}

.Description,
.Titre {
  text-align: center;
}

.Illustration {
  width: 100px;
  margin-bottom: 10px;
}
