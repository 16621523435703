/********* MENU UTILISATEUR *************/
.InfoUtilisateur {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.BoutonsLegaux {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Menu {
  border: 0px solid blue;
  min-width: 350px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .InfoUtilisateur {
    display: none;
  }

  .BoutonsLegaux {
    margin-bottom: 0px;
  }

  .Menu {
    min-width: unset;
  }
}
