.JoursEssai {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100% !important;
}

.Textes {
  border: 0px solid orange;
  max-width: 100%;
}

.Description {
  max-width: 600px;
  min-width: 300px;
  width: 100%;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .JoursEssai {
    min-width: 00px;
  }

  .Description {
    max-width: 100%;
    min-width: 100px;
    width: 100%;
  }
}
