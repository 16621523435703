.PageMessages {
  padding: 20px;
  height: calc(100vh - 230px);
  overflow-y: scroll;
  background-color: white;
}

.Content {
  min-height: calc(100vh - 450px);
  margin-bottom: 40px;
  overflow: scroll;
  margin-top: 30px;
}

.Formulaire {
  display: flex;
  flex-direction: row;
  background-color: cyan;
}

.ListeTelephones {
  display: flex;
  flex-direction: row;
}

.ObjetTelephone {
  background-color: #f6f6f6;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #f6f6f6;
  cursor: pointer;
}

.ObjetTelephone:hover {
  background-color: #208ab010;
}

.ObjetTelephoneSelected {
  background-color: #208ab020;
  border: 1px solid #208ab0;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.AucunNumero {
  background-color: #f6f6f6;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
