.SurfacePapier {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .SurfacePapier {
    padding: 20px;
  }
}
