.PageHome {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.Informations {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}
