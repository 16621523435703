.DataPickerItem {
  border: 1px solid var(--border);
  width: fit-content;
  min-height: 40px;
  border-radius: 6px;
  padding: 1px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
}

.DataPickerItem:hover {
  border: 2px solid var(--bleuDark);
  padding: 0px 10px;
}

.DataPickerItemSelected {
  border: 2px solid var(--bleuDark);
  width: fit-content;
  min-height: 40px;
  border-radius: 6px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bleuDark);
  color: white;
  font-weight: bolder;
  cursor: pointer;
  box-sizing: border-box;
}

.DataPicker {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
