.ObjetDocument {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.IconeEtInformations {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 40px);
}

.Informations {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -2px;
  padding-bottom: 3px;
  overflow: hidden;
  margin-right: 10px;
}

.InformationSecondaireEtTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
}

.Menu {
  min-width: 40px;
}
.Tags {
  padding-top: 1px;
}

.IconeNouveau {
  min-width: 16px;
  width: 16px;
}

.Erreur {
  color: red;
}

.ConfirmationSuppression {
  display: flex;
  flex-direction: column;
}
