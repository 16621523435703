.ListeType {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TypeDemande {
  border: 2px solid #ddd;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 8px;
}

.TypeDemande:hover {
  border: 2px solid #208ab0;
}
