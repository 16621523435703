.AffichageDates {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Titre1 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 30px;
  color: #205a71;
  margin: 0px 10px;
}

.BoutonRond {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Titre1 {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #205a71;
    margin: 0px 10px;
  }

  .BoutonRond {
    display: none;
  }
}
