.PageRecherche {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 110px);
  height: 100%;
  padding: 30px;
}

.TitrePage {
  margin: 10px auto;
  text-align: center;
}

.ZoneRecherche {
  margin: 30px auto;
}

.Tableau {
  border-top: 1px solid #dddddd;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .PageRecherche {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 110px);
    height: 100%;
    padding: 15px;
  }
  .TitrePage {
    /*display: none;*/
  }

  .ZoneRecherche {
    margin: 0px 0px 20px;
  }
}
