.PageDemande {
  padding: 0px 40px 0px 40px;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-height: 100%;
  position: relative;
  overflow: scroll;
  border: 0px solid orange;
  min-height: 100%;
}

.Entete {
  background-color: white;
  position: sticky;
  top: 0px;
  padding-top: 20px;
  z-index: 3;
  display: flex;
  flex-direction: column;
}

.TitreEtBoutons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.TitreEtat {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.EditionDemande {
  position: sticky;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 20px 0px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageDemande {
    padding: 0px 15px;
    height: 100vh;
  }

  .Entete {
    background-color: white;
    position: unset;
    top: 0px;
    padding-top: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }
}
