.IconeContactsPicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.Bouton {
  border: 1px solid #dddddd;
  flex: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
  cursor: pointer;
  background-color: #dddddd20;
}

.Bouton:hover {
  border: 1px solid #208ab0;
  color: #208ab0;
  flex: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
}

.BoutonSelected {
  border: 1px solid #208ab0;
  background-color: white;
  flex: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
  cursor: pointer;
}
