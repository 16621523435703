.Panneau {
  width: 400px;
  padding: 20px;
  max-width: 90vh;
}

.BoutonTitre {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Formulaire {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .Panneau {
    width: 300px;
  }
}
