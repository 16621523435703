.TypeRdv {
  height: 50px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  position: relative;
}

.Couleur {
  min-width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.Couleur:hover {
  background-color: #ededed;
  cursor: pointer;
}

.CouleurLocked {
  min-width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: not-allowed;
}

.Libelle {
  text-align: left;
  width: 80%;
  margin-right: 10px;
}

.Duree {
  width: 15%;
  min-width: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 10px;
}
