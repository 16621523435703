.SelecteurSexe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Choix {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 46%;
  background: #ffffff;
  border: 1px solid #208ab0;
  border-radius: 4px;
  color: #208ab0;
  cursor: pointer;
}
.NonChoix {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 46%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #97989d;
  cursor: pointer;
}

.NonChoix:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 140px;
  width: 46%;

  border: 1px solid #208ab0;

  border-radius: 4px;
  color: #97989d;
  cursor: pointer;
}

.Sexe {
  font-weight: 700;
}

.TexteSecondaire {
  font-weight: 300;
  font-size: 14px;
}

.IconeHommeFemme {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
