.Cabinet {
  background-color: #f6f6f6;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border: 2px solid white;
}

.Cabinet:hover {
  border-radius: 10px;
  border: 2px solid #208ab0;
  height: 50px;
  margin-bottom: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ListeCabinets {
  margin-top: 20px;
}
