.CarteBancaire {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 10x solid orange;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
}

.Recto {
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  min-height: 180px;
  background-image: linear-gradient(153deg, #205a71 0%, #208ab0 95%);
  border: 1px solid #ffffff;
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: white;
  max-width: 300px;
}

.PuceCarteBancaire {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.Formulaire {
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  margin-top: 10px;
  width: 90%;
  gap: 15px;
}

.Numero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0px solid orange;
}

.LabelDate {
  font-size: 11px;
}

.DateExpiration {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  border: 0px solid green;
}

.Nom {
  border: 0px solid pink;
}
