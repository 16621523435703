.EnteteAgenda {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 30;
  height: fit-content;
  width: 100%;
  gap: 0px;
  background-color: white;
  margin-bottom: 5px;
  padding-top: 10px;
}

.ContentEnteteAgenda {
  padding: 0px 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
}

.EnteteColonnes {
  margin-left: 50px;
}

.Agenda {
  padding: 0px 20px;
}

@media screen and (max-width: 820px) {
  .ContentEnteteAgenda {
    padding: 0px;
    width: 100%;
    box-sizing: border-box;
  }

  .EnteteAgenda {
    padding-top: 0px;
  }

  .EnteteColonnes {
    margin-left: 0px;
  }

  .Agenda {
    padding: 0px;
  }
}
