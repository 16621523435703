.PageAdpx {
  display: flex;
  flex-direction: row;
  position: relative;
}

.PartieGauche {
  flex: 1;
  padding-right: 5px;
  border-right: 1px solid #ddd;
}

.MenuAdpx {
  padding-top: 20px;
  width: 60px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 2px;
  min-height: calc(100vh - 70px);
  box-sizing: border-box;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageAdpx {
    border: 0px solid red;
    flex-direction: column;
  }

  .MenuAdpx {
    border-top: 1px solid #ddd;
    position: sticky;
    bottom: 0px;
    padding-top: 0px;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    padding-right: 2px;
    box-sizing: border-box;
    height: 60px;
    min-height: 60px;
  }
}
