.Main {
  border: 0px solid red;
}

.SurfacePopUp {
  width: 60vw;
  max-width: 700px;
}

@media screen and (max-width: 768px) {
  .Header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .BoutonAjouter {
    width: 100%;
    margin-top: 30px;
  }
}
