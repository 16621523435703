.PageMenuSuiviReglements {
  min-width: 320px;
}

.Bloc {
  margin-top: 30px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageMenuSuiviReglements {
    min-width: 340px;
    width: calc(100vw - 30px);
    box-sizing: border-box;
  }
}
