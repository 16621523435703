.PageMotdepassePerdu {
  display: flex;
  flex-direction: row;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

.Formulaire {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Illustration {
  margin-bottom: 40px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
}
