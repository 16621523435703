.Notification {
  position: fixed;
  z-index: 100;
  right: 20px;
  min-height: 80px;
  border: 1px solid #dddddd;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  width: 400px;
  justify-content: space-between;
}

.Icone {
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Textes {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
}

.Titre {
  font-weight: 900;
  color: black;
  font-size: 16px;
}

.SousTitre {
  font-size: 14px;
  color: black;
  font-weight: 500;
}

.Texte {
  font-size: 14px;
  color: #666;
  overflow: hidden;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@media screen and (max-width: 820px) {
  .Notification {
    max-width: 300px;
    width: 300px;
  }
}
