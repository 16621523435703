.JourPlanningPicker {
}

.Groupe {
  padding: 10px;
}

.Mois {
  height: 20px;
  border: 1px solid #ddd;
  padding: 0px 10px;
  text-transform: uppercase;
  background-color: #213945;
  border: 1px solid #213945;
  color: white;
  box-sizing: border-box;
}

.Jour {
  height: 20px;
  border: 0px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}

.Lundi {
  height: 20px;
  border-top: 1px solid #213945;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}

.Jour:hover,
.Lundi:hover {
  background-color: #208ab050;
}
