.ZoneConnexion {
  min-width: 400px;
  width: 20%;
  overflow: scroll;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.Entete {
  width: 100%;
  display: flex;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .ZoneConnexion {
    min-width: 100%;
    width: 100%;
    overflow: scroll;
  }

  .Entete {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
