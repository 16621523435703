.PageAllergies {
  box-sizing: border-box;
  height: fit-content;
  padding: 10px;
  width: 100%;
  /* background-color: var(--gris); */
  padding-bottom: 80px;
}

.AllergiesEtAntecedentsEtHabitus {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.AllergiesEtAntecedents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 30px;
  border: 0px solid green;
}

.Antecedents {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 30%;
  min-width: 320px;
  background-color: white;
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.PartieDroiteHabitus {
  min-width: 300px;
  max-width: 30%;
  background-color: var(--gris);
  box-sizing: border-box;
}

.ListeHabitus {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/********* TAILLE MOYEN+PETIT ECRAN *************/
@media screen and (min-width: 820px) and (max-width: 1550px) {
  .Antecedents {
    display: flex;
    flex-direction: column;
    max-width: unset;
    width: 100%;
    min-width: 320px;
    padding: 20px 10px;
    box-sizing: border-box;
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .PageAllergies {
    margin: 0px;
    height: 100%;
    overflow-y: unset;
    padding: 0px;
  }
  .AllergiesEtAntecedents {
    padding: 10px;
  }

  .Antecedents {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    min-width: 320px;
    background-color: white;
    padding: 20px 10px;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .AllergiesEtAntecedentsEtHabitus {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
