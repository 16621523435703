.PageJournalDesReglements {
  min-height: calc(100vh);
}

.TitreEtBoutons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.BoutonRetour {
  margin-top: 20px;
  display: none;
}

.Filtres {
  min-height: 40px;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ListeReglements {
  display: flex;
  flex-direction: column;
}

.ColonneDate {
  font-size: 14px;
  color: #666;
  min-width: 80px;
  display: flex;
  justify-content: center;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageJournalDesReglements {
    width: calc(100vw - 60px);
    box-sizing: border-box;
  }

  .TitreEtBoutons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 0px;
  }
  .ColonneDate {
    display: none;
  }
  .BoutonRetour {
    margin-top: 20px;
    display: flex;
  }
  .Filtres {
    flex-direction: column;
    margin: 20px 0px;
  }
}
