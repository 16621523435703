.Content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.PartieGauche {
  border-right: 1px solid #ddd;
  min-height: 100vh;
  box-sizing: border-box;
  padding-right: 20px;
}

.PartieDroite {
  width: 100%;
  padding-left: 20px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PartieGauche {
    border-right: none;
    padding-right: 0px;
    width: auto;
  }

  .PartieDroite {
    padding-left: 0px;
  }
}
