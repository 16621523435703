.SelectHeure {
  min-width: 65px;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 0px 4px;
  text-align: center;
}

.HorairesJour {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.Label {
  font-size: 14px;
  color: #666;
}

.PlageHoraire {
  background-color: #f6f6f6;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 8px;
  height: 40px;
  width: 43%;
}
