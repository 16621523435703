.Inscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0px solid red;
  box-sizing: border-box;
}

.Formulaire {
  width: 400px;
  margin-top: 0px;
  box-sizing: border-box;
  border: 0px solid blue;
}

.Centre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Formulaire {
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
  }
}
