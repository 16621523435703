.PageHistoriqueConsultations {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border: 0px solid red;
  padding: 20px;
  padding-bottom: 0px;
  width: 30%;
  max-width: 30%;
  min-width: 380px;
  min-height: 100%;
  max-height: 100%;
  border: 0px solid orange;
  overflow-y: scroll;
  border-right: 1px solid #dddddd;
}

/* .PartieGauche {
  min-width: 380px;
  width: 35%;
  padding: 20px;
  padding-bottom: 0px;
  padding-right: 30px;
  overflow: scroll;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100vh - 180px);
  border: 0px solid purple;
  border-right: 1px solid #ddd;
}

.PartieDroite {
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  border: 0px solid purple;
} */

.Entete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}

.BoutonsAction {
  display: flex;
  flex-direction: row;
}

.Filtres {
  padding: 20px 0px;
}

.Historique {
  padding-bottom: 40px;
  box-sizing: border-box;
}

.Groupe {
  border-top: 1px solid #ddd;
  margin-bottom: 40px;
}

.IllustrationPiedPage {
  margin-bottom: 0px;
  margin-top: auto;
  max-width: 100%;
}

/********* TAILLE PETIT ECRAN *************/

@media screen and (min-width: 820px) and (max-width: 1340px) {
  .PageHistoriqueConsultations {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    max-width: 100vh;
    width: 100%;
  }

  .PartieDroite {
    display: none;
  }

  .PartieGauche {
    width: 100%;
    max-width: 100%;
  }
}

/********* MOBILE *************/

@media screen and (max-width: 820px) {
  .PageHistoriqueConsultations {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    max-width: 100vh;
    width: 100%;
  }

  .PartieDroite {
    display: none;
  }

  .IllustrationPiedPage {
    height: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: -70px;
    display: none;
  }

  .PartieGauche {
    min-width: 300px;
    overflow: unset;
    background-color: #f6f6f6;
    width: 100%;
    padding: 10px;
    padding-bottom: 0px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
    height: fit-content;
  }
}
